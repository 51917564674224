














































































































































































































import { successNotification } from '@/includes/NotificationService'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'
import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";

import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from "moment";

@Component({
  'components': {
    VueApexCharts,
    Icon
  },
})
export default class LinkCard extends Vue {
  @Prop({ type: String }) title!: string

  @Prop({ type: String }) code!: string

  @Prop({ type: Number }) price!: number

  @Prop({ type: Number }) enter!: number

  @Prop({ type: Number }) kick!: number

  @Prop({ type: Number }) leave!: number

  @Prop({ type: String }) time!: string

  @Prop({ type: Number }) count!: number

  @Prop({ type: String }) inviteLink!: string

  @Prop({ type: Number }) maxValue!: number

  @Prop() tags!: Array<string>

  @Prop() series!: ApexChartSeries

  @Prop() chartType !: 'line' | 'bar'

  @Emit()
  statisticButtonClick() {
    return this.code
  }

  @Emit()
  editButtonClick() {
    return this.code
  }

  @Emit()
  deleteButtonClick() {
    return this.code
  }

  get barOptions(): ApexOptions {
    return {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        labels: {
          formatter(val: number, opts?: any): string | string[] {
            return val.toFixed(0)
          },
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      }
    }
  }

  get lineOptions(): ApexOptions {
    return {
      chart: {
        sparkline: {
          enabled: true
        },
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      xaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        max: this.maxValue ?? undefined,
        labels: {
          formatter(val: number, opts?: any): string | string[] {
            return val.toFixed(0)
          },
          show: false
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'YYYY-MM-DD HH:mm:ss',
          show: false
        },
      },
    }
  }

  get hasBaseMetricsValues() {
    return this.enter > 0 || this.kick + this.leave > 0
  }

  get clearSubscriber(): string | undefined {
    if (this.price) {
      const clearPrice = Number(this.price / Number(this.enter - this.kick - this.leave))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }
  }

  get dirtySubscriber(): string | undefined {
    if (this.price) {
      const clearPrice = Number(this.price / Number(this.enter))

      if (clearPrice !== Infinity) {
        return clearPrice.toFixed(2)
      }

      return '-'
    }
  }

  copyLinkToClipboard(linkUrl: string) {
    const copy = copyTokenToClipboard(linkUrl)

    if (copy) {
      successNotification(this.$t('copy_link_to_buffer').toString())
    }
  }

  get formattedTime() {
    if (this.time) {
      return moment(this.time).format('LLL')
    }

    return ''
  }
}
