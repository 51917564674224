


























































































































































































































import LinkCard from '@/components/chat/InviteLinks/LinkCard.vue'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import {
  InviteLinkToCreate, InviteLinkToEdit,
  InviteLinkWithStat,
  LocalInviteLink, NewInviteLink,
} from "@/includes/logic/InviteLinks/types";
import {
  createInviteLink,
  deleteInviteLink,
  editInviteLink, findOne,
  getInviteLinks,
  getInviteLinksStat,
  prepareInviteLinks,
  prepareSimpleSeriesForLinks, removeLinkFromList,
  updateLink
} from "@/includes/logic/InviteLinks/InviteLinksHelper";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Mixins, Watch, Component } from 'vue-property-decorator'
import { max, sumBy } from 'lodash'

export enum ModalModeType {
  Create,
  Edit,
}

@Component({
  'components': {
    LinkCard,
    PageTitle,
    List,
    HelpMessage,
    EmptyData
  },
  data() {
    return {
      ListDirection,
      ModalModeType
    }
  }
})
export default class LinkList extends Mixins(UseFields, InputSetups) {
  modalMode: ModalModeType | null = null

  linkModel: InviteLinkToEdit |  InviteLinkToCreate | null = null

  isModalOpen = false

  inviteLinks: Array<LocalInviteLink> | null = null

  maxValue: number | null = null

  @Watch('$route.params.CHAT_ID')
  onChatIdChange() {
    this.getLinks()
  }

  get modalTitle() {
    if (this.modalMode === ModalModeType.Create) {
      return this.$t('link_list_create').toString()
    } else if (this.modalMode === ModalModeType.Edit) {
      return this.$t('link_list_edit').toString()
    } else {
      return ''
    }
  }

  get modalOkButtonTitle() {
    if (this.modalMode === ModalModeType.Create) {
      return this.$t('link_list_popup_link_generate_button').toString()
    } else if (this.modalMode === ModalModeType.Edit) {
      return this.$t('save_button').toString()
    } else {
      return ''
    }
  }

  handleOkButtonClick() {
    if (this.linkModel) {
      if (this.modalMode === ModalModeType.Create && !('code'in this.linkModel)) {
        this.createLink(this.linkModel)
      } else if (this.modalMode === ModalModeType.Edit && 'code' in this.linkModel) {
        this.editLink(this.linkModel)
      }
    }
  }

  searchFunction(item: InviteLinkWithStat, index: number, text: string) {
    const searchQuery = text.toLowerCase()
    const { link } = item

    if (link && link.tags) {
      return link.tags.includes(searchQuery) || link.title.toLowerCase().includes(searchQuery)
    }

    return false
  }

  createLink(linkObject: InviteLinkToCreate) {
    createInviteLink(this.$store.getters.chatId, linkObject)
      .then((res) => {
        if (res) {
          successNotification()

          this.isModalOpen = false

          this.getLinks()
        }
      })
  }

  deleteLink(code: NewInviteLink['code']) {
    deleteInviteLink(this.$store.getters.chatId, code)
      .then(res => {
        if (res) {
          this.inviteLinks = removeLinkFromList(this.inviteLinks!, code)

          successNotification()
        }
      })
  }

  editLink(newLink: InviteLinkToEdit) {
    editInviteLink(this.$store.state.chatState.chat.chat_id, newLink)
      .then((res) => {
        if (res) {
          if (this.inviteLinks) {
            this.inviteLinks = updateLink(this.inviteLinks, res)
          }

          successNotification()

          this.isModalOpen = false
        }
      })
  }

  onStatClick(code: LocalInviteLink['link']['code']) {
    this.$router.push({ name: 'invite_links_statistics', query: { code } })
  }

  onCreateButtonClick() {
    this.linkModel = this.defaultCreateLinkModel()

    this.modalMode = ModalModeType.Create
    this.isModalOpen = true
  }

  onEditClick(code: LocalInviteLink['link']['code']) {
    const getFieldForEdit = (link: LocalInviteLink['link']): InviteLinkToEdit => {
      return {
        code: link.code,
        tags: link.tags,
        title: link.title
      }
    }

    if (this.inviteLinks) {
      const link = findOne(this.inviteLinks, code)

      if (link) {
        this.linkModel = getFieldForEdit(link)

        this.modalMode = ModalModeType.Edit
        this.isModalOpen = true
      }
    }
  }

  onDeleteClick(code: LocalInviteLink['link']['code']) {
    this.$confirm({
      title: this.$t('invite_link_delete_warn_title').toString(),
      content: this.$t('invite_link_delete_warn_text').toString(),
      onOk: () => {
        this.deleteLink(code!)
      }
    })
  }

  clearAllFields() {
    this.linkModel = null
    this.modalMode = null
  }

  defaultCreateLinkModel(): InviteLinkToCreate {
    return {
      title: '',
      count: 0,
      time: '',
      tags: [],
      price: 0
    }
  }

  get activeInviteLinks() {
    return Array.isArray(this.inviteLinks) && this.inviteLinks.filter(link => !!link.stat)
      .sort((a, b) => {
        return sumBy(b.stat, 'enter_unique_first_users') - sumBy(a.stat, 'enter_unique_first_users') ||
          sumBy(b.stat, 'enter_unique_repeated_users') - sumBy(a.stat, 'enter_unique_repeated_users') ||
          sumBy(b.stat, 'leave_unique_users') - sumBy(a.stat, 'leave_unique_users')
      }) || []
  }

  get nonActiveInviteLinks() {
    return Array.isArray(this.inviteLinks) && this.inviteLinks
      .filter(link => !link.stat)
      .sort((a, b) => b.link.enter - a.link.enter || b.link.kick - a.link.kick) || []
  }

  getLinks() {
    this.$baseTemplate.loader.open()

    getInviteLinks(this.$store.getters.chatId)
      .then((links) => {
        getInviteLinksStat(this.$store.getters.chatId, { interval: 'day' })
          .then((stat) => {

            const maxValue = max(stat.map(l => (l.stat ?? []).map(s => [ s.enter_unique_first_users, s.kick_unique_users, s.leave_unique_users ]).flat()).flat())

            if (maxValue !== undefined) {
              this.maxValue = maxValue
            }

            prepareSimpleSeriesForLinks(prepareInviteLinks(links, stat), this.$i18n)
              .then(res => {
                this.inviteLinks = res
              })
              .catch((e) => {
                errorNotification(e)

                this.inviteLinks = []
              })
              .finally(() => {
                this.$baseTemplate.loader.close()
              })
          })
      })
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    this.getLinks()
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}

